export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: ["user.sidebar.use_loonity"],
      },
      {
        _name: "CSidebarNavItem",
        name: "user.sidebar.dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
        badge: {
          color: "danger",
          text: "HOT",
        },
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: "user.sidebar.showcase",
      //   to: '/showcase',
      //   icon: 'cil-speedometer',
      //   badge: {
      //     color: 'primary',
      //     text: 'NEW'
      //   }
      // },
      {
        _name: "CSidebarNavItem",
        name: "user.sidebar.markets",
        to: "/shop",
        icon: "cil-speedometer",
        badge: {
          color: "danger",
          text: "New",
        },
      },
      {
        _name: "CSidebarNavDropdown",
        name: "user.sidebar.orders",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "user.sidebar.orders_current",
            to: "/orders/current",
            icon: "cil-puzzle",
          },
          {
            name: "user.sidebar.orders_archive",
            to: "/orders/archive",
            icon: "cil-puzzle",
          },
          {
            name: "user.sidebar.orders_subscription",
            to: "/orders/subcription",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "user.sidebar.wallet",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "user.sidebar.wallet_moves",
            to: "/wallet/moves",
            icon: "cil-puzzle",
          },
          {
            name: "user.sidebar.wallet_invoices",
            to: "/wallet/invoices",
            icon: "cil-puzzle",
          },
          {
            name: "user.sidebar.wallet_recharge",
            to: "/wallet/charge",
            icon: "cil-puzzle",
          },
          {
            name: "user.sidebar.wallet_transactions",
            to: "/wallet/transactions",
            icon: "cil-puzzle",
          },
        ],
      },
      // {
      //     _name: 'CSidebarNavDropdown',
      //     name: "user.sidebar.profile",
      //     route: '/dropdown',
      //     icon: 'cil-puzzle',
      //     items: [{
      //             name: "user.sidebar.profile_data",
      //             to: '/user/profile',
      //             icon: 'cil-puzzle'
      //         },
      //         {
      //             name: "user.sidebar.profile_social",
      //             to: '/user/social',
      //             icon: 'cil-puzzle'
      //         },
      //         {
      //             name: "user.sidebar.profile_email",
      //             to: '/user/email',
      //             icon: 'cil-puzzle'
      //         },
      //         {
      //             name: "user.sidebar.profile_families",
      //             to: '/user/families',
      //             icon: 'cil-puzzle'
      //         },
      //     ]
      // },
    ],
  },
];
