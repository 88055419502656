<template>
  <CSidebar
    colorScheme="dark"
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/">
      <img
        height="40"
        alt="Loonity Logo"
        class="c-sidebar-brand-full"
        src="./../../public/loonity/brand/LoonityLogoWhite.svg"
      />
      <img
        height="40"
        alt="Loonity Logo"
        class="c-sidebar-brand-minimized"
        src="./../../public/loonity/brand/LoonityLogoWhiteSyg.png"
      />
    </CSidebarBrand>
    <TCRenderFunction flat :contentToRender="$options.nav_user" />
    <!-- <div class="loonityWorkLink">
      <CButton size="lg" color="primary" block @click="$store.commit('toggle', 'asideShow')">{{ $t("common.work_with_us") }}</CButton>
    </div> -->
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import nav_user from "./_nav_user";
import TCRenderFunction from "../common/TCRenderFunction";
// import nav_user from "./_nav_user";
export default {
  name: "TheSidebar",
  nav_user,
  components: {
    TCRenderFunction,
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>
